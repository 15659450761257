
import RoleService from '@/services/RoleService';
import LocalStorageService from '@/services/LocalStorageService';
import CategoryService from '@/services/CategoryService';
import ItemService from '@/services/ItemService';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Category',
  components: {
  },
  props: {
    items: {
      required: true,
      default: [],
    },
    btnClass: {
      required: false,
      default: 'btn-sm',
    },
  },
  data() {
    return {
      showCategoryModal: false,
      showCategoryItems: false,
      selectedCategoryName: '',
      itemList: [],
      selectedItems: [],
      categoryName: '',
      isPopupBlock: false,
      editingCategory: null,
      categoryIdForEdit: '',
      categoryLocationId: null,
      noRecord: false,
      disableSaveButton: true,
      selectedLocationId: '',
      page: 1,
      pageSize: 200,
      totalCount: 0,
      currentPage: 1,
      nextPage: 0,
      lastPage: 0,
      orderBy: 'name',
      previusePage: 0,
      records: 0,
      categoryList: [],
      activateNotification: false,
      typeNotification: 'success',
      titleNotification: '',
      messageNotification: '',
      deleteConfirmationMessage: '',
      searchTerm: '',
      selectedCategory: {},
    };
  },
  async mounted() {
    this.loading = true;
    this.selectedLocationId =
      this.$route.query.locationId?.toString() ||
      this.localStorageService.getItem('primary_location_id');

    await this.getAllCategory(this.selectedLocationId);
  },
  created() {
    this.roleService = new RoleService(new LocalStorageService());
    this.localStorageService = new LocalStorageService();
    this.categoryService = new CategoryService();
    this.itemService = new ItemService();
  },
  computed: {
    checkboxState() {
      return (item) => item.category !== null;
    },
  },

  methods: {
    triggerCategoryModal() {
      this.categoryName = '';
      this.categoryIdForEdit = '';
      this.editingCategory = null;
      this.showCategoryModal = true;
      this.categoryLocationId = null;
    },

    confirmationCancel() {
      this.showCategoryModal = false;
    },

    cancelModal() {
      this.showCategoryModal = false;
      this.showCategoryItems = false;
      this.categoryName = '';
      this.selectedItems = [];
    },

    handleInputChange() {
      this.disableSaveButton = this.categoryName == '';
    },

    showModalConfirm(categoryId: string, categoryName: string) {
      this.deleteCategoryId = categoryId;
      this.deleteConfirmationMessage = `Are you sure you want to delete <strong>${categoryName}</strong>?`;
      this.isPopupBlock = true;
    },

    confirmationOk() {
      this.isPopupBlock = false;
      this.deleteCategoryConfirmed();
    },

    displayNotification(type: 'success' | 'error', msg: string, title = '') {
      this.activateNotification = true;
      this.typeNotification = type;
      this.titleNotification = title;
      this.messageNotification = msg;
    },

    async changeLocation() {
      try {
        this.loading = true;
        await this.getAllCategory(this.selectedLocationId);
      } catch (error) {
        this.displayNotification('error', error, 'error');
      } finally {
        this.loading = false;
      }
    },

    openEditModal(category) {
      this.editingCategory = { ...category };
      this.categoryName = this.editingCategory.name;
      this.categoryIdForEdit = this.editingCategory.id;
      this.categoryLocationId =
        this.editingCategory?.categoryLocation?.[0]?.id || '';
      this.showCategoryModal = true;
    },

    async openAssignProductDialog(category) {
      this.loading = true;
      this.selectedCategory = category;
      this.selectedCategoryName = category.name;
      try {
        const itemResp = await this.categoryService.getProductsCategory(
          category.id,
          this.selectedLocationId,
          1,
          this.pageSize,
          this.orderBy,
          this.searchTerm,
        );
        this.itemList = itemResp.data.items;
        for (const a of this.itemList) {
          if (a.category) {
            this.selectedItems.push(a);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
      this.showCategoryItems = true;
    },

    async deleteCategoryConfirmed() {
      try {
        this.loading = true;
        await this.categoryService.deleteCategory(
          this.deleteCategoryId,
          this.selectedLocationId,
        );
        await this.getAllCategory(this.selectedLocationId);
      } catch (error) {
        this.displayNotification('error', error, 'error');
      } finally {
        this.loading = false;
      }
    },

    confirmationPopupCancel() {
      this.isPopupBlock = false;
    },

    async handleSaveForCategoryItem() {
      try {
        const selectedItemsForPayload = this.selectedItems.map((item) => {
          return { id: item.id, name: item.name };
        });
        const payloadData = {
          items: selectedItemsForPayload,
          ...this.selectedCategory,
        };
        const result = await this.categoryService.updateItemCategory(
          this.selectedCategory.id,
          this.selectedLocationId,
          payloadData,
        );

        if (result.status == 200) {
          this.displayNotification('success', result.data.message, 'success');
          this.cancelModal();
        }
      } catch (error) {
        this.displayNotification('error', error, 'error');
      } finally {
        this.loading = false;
      }
    },
    async handleSave() {
      const location = JSON.parse(
        this.localStorageService.getItem('locations'),
      );
      const locationDetails = location.filter(
        (loc) => loc.id == this.selectedLocationId,
      );

      const payloadData = {
        ...(this.categoryIdForEdit ? { id: this.categoryIdForEdit } : {}),
        name: this.categoryName,
        categoryLocation: this.categoryIdForEdit
          ? [
              {
                id: this.categoryLocationId,
                location: {
                  id: locationDetails[0].id,
                  name: locationDetails[0].name,
                },
                isActive: true,
              },
            ]
          : [
              {
                location: {
                  id: locationDetails[0].id,
                  name: locationDetails[0].name,
                },
                isActive: true,
              },
            ],
      };

      try {
        this.loading = true;
        if (this.categoryIdForEdit) {
          const result = await this.categoryService.editCategory(
            this.categoryIdForEdit,
            this.selectedLocationId,
            payloadData,
          );
          if (result.status == 200) {
            this.displayNotification('success', result.data.message, 'success');
          }
        } else {
          const result = await this.categoryService.createCategory(payloadData);
          if (result.status == 200) {
            this.displayNotification('success', result.data.message, 'success');
          }
        }

        this.cancelModal();
        await this.getAllCategory(this.selectedLocationId);
      } catch (error) {
        this.displayNotification('error', error, 'error');
      } finally {
        this.loading = false;
      }
    },

    async getAllCategory(location) {
      this.loading = true;
      if (location) {
        const locationId = location;
        const result = await this.categoryService.getAllCategories(
          locationId,
          this.page,
          this.pageSize,
          this.orderBy,
        );
        this.categoryList = result.data.categories;
        this.noRecord = this.categoryList.length ? false : true;
      }
      this.loading = false;
    },
  },
});
